import { inject } from '@angular/core';
import type { ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { Router } from '@angular/router';

export function queryParamsMergingGuard(
  childRoute: ActivatedRouteSnapshot,
): UrlTree | boolean {
  const router = inject(Router);
  if (
    !childRoute.queryParams['cache-bypass'] &&
    !childRoute.queryParams['ngsw-bypass']
  ) {
    return true;
  }

  let current = childRoute;
  let segments = <string[]>[];

  while (current) {
    if (current.url) {
      segments = segments.concat(current.url.map((m) => m.path));
    }
    current = current.firstChild;
  }

  return router.createUrlTree(segments, {
    queryParams: {
      ...(childRoute.queryParams || {}),
      'ngsw-bypass': null,
      'cache-bypass': null,
    },
  });
}
